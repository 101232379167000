'use strict';

angular.module('cpformplastApp.jobs')
  .config(function ($stateProvider) {
    $stateProvider
      .state('jobs/job-management', {
        url: '/jobs/job-management',
        templateUrl: 'app/jobs/job-management/job-management.html',
        controller: 'jobManagementController',
        authenticate: ['admin','administration_dept', 'management_dept', 'logistic_dept', 'salesmen_dept', 'receptionist']
      })
      .state('jobs/job-management/job-creation', {
        url: '/jobs/job-management/job-creation/:jobId',
        templateUrl: 'app/jobs/job-management/job-creation/job-creation.html',
        controller: 'jobCreationController',
        authenticate: ['admin','administration_dept', 'management_dept', 'logistic_dept', 'receptionist']
      })
      .state('jobs/machine-schedule', {
        url: '/jobs/machine-schedule',
        templateUrl: 'app/jobs/machine-schedule/machine-schedule.html',
        controller: 'machineScheduleController',
        authenticate: ['admin', 'management_dept', 'logistic_dept', 'receptionist']
      })
      .state('jobs/job-planning', {
        url: '/jobs/job-planning',
        templateUrl: 'app/jobs/job-planning/job-planning.html',
        controller: 'jobPlanningController',
        authenticate: ['admin', 'management_dept', 'logistic_dept', 'mold_dept', 'quality_dept', 'receptionist']
      })
      .state('jobs/job-planning/new-jobs', {
        url: '/jobs/job-planning/new-jobs',
        templateUrl: 'app/jobs/job-planning/new-jobs/new-jobs.html',
        controller: 'newJobsController',
        authenticate: ['admin', 'management_dept', 'logistic_dept', 'quality_dept', 'receptionist']
      })
      .state('jobs/job-history', {
        url: '/jobs/job-history',
        templateUrl: 'app/jobs/job-history/job-history.html',
        controller: 'jobHistoryController',
        authenticate: ['admin', 'management_dept', 'logistic_dept', 'receptionist'],
      });
  });
